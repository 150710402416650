import React from "react";
import OwlCarousel from 'react-owl-carousel';
import Title from "../extra/Title";
import { BestCollection } from "./BestSeller";
import Saree from "../../assets/images/saree.png"
import Saree1 from "../../assets/images/saree1.png"
import Saree2 from "../../assets/images/saree2.png"
import Saree3 from "../../assets/images/saree3.png"
function News() {
  const options = {
    responsive: {
      0: {
        items: 2
      },
      600: {
        items: 3
      },
    }
  }
  return (
    <section id='News' className='carousel-mini'>
      <div className='container'>
        <Title name={`Our New`} />

        <OwlCarousel className='owl-theme py-5' {...options} nav navClass={['fa-solid fa-angle-left prev', 'fa-solid fa-angle-right next']} margin={30}>

          <BestCollection className={`item`} image={Saree3} head={"Black High Waist Scrunch Leggings"} color={"Black"} />
          <BestCollection className={`item`} image={Saree1} head={"Black High Waist Scrunch Leggings"} color={"Black"} />
          <BestCollection className={`item`} image={Saree2} head={"Black High Waist Scrunch Leggings"} color={"Black"} />
          <BestCollection className={`item`} image={Saree} head={"Black High Waist Scrunch Leggings"} color={"Black"} />
          <BestCollection className={`item`} image={Saree2} head={"Black High Waist Scrunch Leggings"} color={"Black"} />
          <BestCollection className={`item`} image={Saree1} head={"Black High Waist Scrunch Leggings"} color={"Black"} />


        </OwlCarousel>
      </div>
    </section>
  );
}

export default News;
