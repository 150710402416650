import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import { useDispatch, useSelector } from "react-redux";
import { budgetGet } from "../../redux/slice/budgetSlice";
import { baseURL } from "../utils/config";
import { useNavigate } from "react-router-dom";
const Budget = () => {

  const dispatch = useDispatch()

  const { budget, isSkeleton } = useSelector((state) => state.budget)

  useEffect(() => {
    dispatch(budgetGet())
  }, []);


  const navigate = useNavigate()
  const handleByBudget = (id) => {
    navigate("/shop/rang", { state: { id } })
  }
  return (
    <section id="Budget">
      <div className="container">
        <Title name={`SHOP BY BUDGET`} />
        <div className="mainBudget mt-5 text-center item">

          <div className={`row`}>
            {
              !isSkeleton ? budget?.map((res) => {
                return (
                  <div className="col-lg-3 col-6 m15-bottom">
                    <BudgetBox className={`skeleton`} image={baseURL + res.image} budget={res.budget} onCLick={() => handleByBudget(res.budget)} />
                  </div>
                )
              }) : [...Array(4)]?.map(() => {
                return (
                  <div className="col-lg-3 col-6 m15-bottom">
                    <BudgetBox className={`skeleton`} />
                  </div>
                )
              })
            }
          </div>



        </div>

      </div>
    </section>
  );
}
export default Budget;


export const BudgetBox = ({ image, budget, className, onCLick }) => {
  return (
    <div id="" onClick={onCLick}>
      <div className={`budgetImage ${className} cursor-pointer fs-xl-40 fs-md-30 fs-20 height-xxl-500 height-lg-350 height-md-500 height-sm-350 height-250`}>
        <img src={image} alt="" className="height-xxl-500 height-lg-350 height-md-500 height-sm-350 height-250" />
        {
          budget &&
          <div className="priceTag">Under ₹{budget}</div>
        }
      </div>
    </div>
  )
}