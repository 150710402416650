import React, { useEffect, useState } from "react";
import Header from "./globle/Header";
import Footer from "./globle/Footer";
import Saree from "../assets/images/saree.png";
import Saree1 from "../assets/images/saree1.png";
import Saree2 from "../assets/images/saree2.png";
import Saree3 from "../assets/images/saree3.png";
import Button from "./extra/Button";
import { useDispatch, useSelector } from "react-redux";
import { removeWishlist, showWishlist } from "../redux/slice/wishlistSlice";
import Cart from "../assets/images/cart.png";
import { ProductSkeleton } from "./components/BestSeller";
import { useNavigate } from "react-router-dom";
import { addCart } from "../redux/slice/cartSlice";
import { baseURL } from "./utils/config";
import Service from "./components/Service";
const Wishlist = () => {
  const { loginUser } = useSelector((state) => state.userAuth);
  const { wishlist, isSkeleton } = useSelector((state) => state.wishlist);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(showWishlist(loginUser._id));
  }, [loginUser._id]);

  return (
    <>
      <Header />
      <div id="wishlist">
        <div className="mainWishlist">
          <div className="container">
            <div className="row">
              {!isSkeleton ? (
                wishlist?.length > 0 ? (
                  <>
                    {wishlist?.map((res) => {
                      return (
                        <div className="col-xxl-3 col-lg-4 col-sm-6 col-12">
                          <ShowMyWishlist data={res} />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <img src={Cart} alt="" className="noData" />
                )
              ) : (
                [...Array(4)]?.map(() => {
                  return (
                    <div className="col-xxl-3 col-lg-4 col-sm-6 col-12">
                      <WishlistSkeleton />
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
      <Service />
      <Footer />
    </>
  );
};

export default Wishlist;

// ======================== ShowMyWishlist =======================
export const ShowMyWishlist = (props) => {
  const { data } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleShowImage = (id) => {
    navigate(`/profile/${id}`, { state: { id } });
    const myDiv = document.getElementById("SareeProfileMain");
    if (myDiv) {
      myDiv.scrollTo(0, 0);
    }
  };
  const handleRemove = (id) => {
    dispatch(removeWishlist(id));
  };
  const { loginUser } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const handleAddToCart = (productId, cnt) => {
    dispatch(
      addCart({
        productId: productId,
        userId: loginUser?._id || parsedSessionUserId?._id,
        productCount: cnt || 1,
      })
    );
  };
  const [count, setCount] = useState(1);

  const handleCheckout = (productDetails, productCount) => {

    const userId = loginUser?._id;
    const {
      discount,
      outOfStock,
      oldPrice,
      price,
      productCode,
      productImage,
      shippingCharge,
      title,
      productId,
    } = productDetails;
    const finalCount = productCount * 1;
    const cart = {
      allProduct: [
        {
          _id: null,
          discount,
          outOfStock,
          oldPrice,
          price,
          productCode,
          productId,
          productCount: finalCount,
          productImage,
          shippingCharge,
          title,
          userId,
          savePrice: oldPrice - price,
          finalOldPrice: oldPrice * finalCount,
          finalPrice: price * finalCount,
          finalSavePrice: (oldPrice - price) * finalCount,
        },
      ],
      oldPrice,
      price,
      finalOldPrice: oldPrice * finalCount,
      finalPrice: price * finalCount,
      finalSavePrice: (oldPrice - price) * finalCount,
      productCount: finalCount,
      totalProduct: 1,
    };
    localStorage.setItem("cart", JSON.stringify(cart));
    navigate("/checkout", { state: cart });
  };

  return (
    <div className="wishlistCard">
      <div className="removeBtn" onClick={() => handleRemove(data?._id)}>
        <i class="ri-close-line"></i>
      </div>
      <div className="row d-flex align-items-center">
        <div
          className="cartImage col-lg-3 col-sm-4 col-smm-3 col-4 m0"
          onClick={() => handleShowImage(data?.productCode)}
        >
          <img
            src={baseURL + data?.productImage[0]}
            alt="cart"
            className="width-80 height-80"
          />
        </div>
        <div className="cartDetails col-lg-9 col-sm-8 col-smm-9 col-8 m0">
          <h5 className="text-overflow-1 fs-lg-18 fs-16 m2-bottom">
            {data?.title}
          </h5>
          <div className="productQUT m10-bottom">
            <span
              className="fw-600 fs-xl-16 fs-14 cntBtn"
              onClick={() => setCount(count <= 1 ? 1 : count - 1)}
            >
              -
            </span>
            <span className="fw-600 fs-xl-16 fs-14 cnt">{count}</span>
            <span
              className="fw-600 fs-xl-16 fs-14 cntBtn"
              onClick={() => setCount(count + 1)}
            >
              {" "}
              +
            </span>
          </div>
          <div className="productPrice m4-top fs-xl-16 fs-14">
            <span className="fw-600 me-2 ">₹{data?.price}</span>
            <span className="fw-600 text-gray">
              <del>₹{data?.oldPrice}</del>
            </span>
          </div>
        </div>
        <div className="col-12">
          {data?.outOfStock ? (
            <div className="themeBtn mt-3 d-flex justify-content-between fs-xl-16 fs-14">
              <button
                type="button"
                className="m10-right border-danger-1 bg-transparent text-danger fw-bold"
              >
                Out of Stock
              </button>
            </div>
          ) : (
            <div className="themeBtn mt-3 d-flex justify-content-between fs-xl-16 fs-14">
              <button
                type="button"
                className="m10-right"
                onClick={() => handleCheckout(data, count)}
              >
                Buy Now
              </button>
              <button
                type="button"
                className="m10-left"
                onClick={() => handleAddToCart(data?.productId, count)}
              >
                {" "}
                Add To Cart
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const WishlistSkeleton = (props) => {
  return (
    <>
      <div className="wishlistCard">
        <div className="row d-flex align-items-center">
          <div className="cartImage col-lg-3 col-sm-4 col-smm-3 col-4 m0">
            <div className="width-80 height-80 skeleton"></div>
          </div>
          <div className="cartDetails col-lg-9 col-sm-8 col-smm-9 col-8 m0">
            <h5 className="text-overflow-1 fs-lg-18 fs-16 height-20 skeleton"></h5>
            <div className="productQUT height-28 skeleton w-30"></div>
            <div className="productPrice m4-top fs-xl-16 fs-14 w-50 height-23 skeleton"></div>
          </div>
          <div className="col-12">
            <div className="themeBtn mt-3 d-flex justify-content-between fs-xl-16 fs-14">
              <button
                type="button"
                className="m10-right height-33 skeleton"
              ></button>
              <button
                type="button"
                className="m10-left height-33 skeleton"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
