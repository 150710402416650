import React, { useEffect } from 'react';
import Card1 from '../../assets/icons/card1.png'
import Card2 from '../../assets/icons/card2.png'
import Card3 from '../../assets/icons/card3.png'
import Card4 from '../../assets/icons/card4.png'
import Card5 from '../../assets/icons/card5.png'
import Card6 from '../../assets/icons/card6.png'
import Card7 from '../../assets/icons/card7.png'
import { useDispatch, useSelector } from 'react-redux';
import { settingGet } from '../../redux/slice/settingSlice';

const Footer = () => {
  const dispatch = useDispatch()
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(settingGet())
  }, []);
  return (
    <section id='Footer'>
      <div className="container">
        <div className="mainFooter text-md-start text-center">
          <div className="row justify-content-between">
            <div className="col-lg-4 col-md-6 col-12 m0-lg-bottom m40-bottom">
              <h5>About Name</h5>
              <p className='m20-top m20-lg-bottom m40-bottom'>
                {setting?.footerText}
              </p>
              <div className="socialIcon">
                <span><i class="fa-brands fa-facebook-f"></i></span>
                <span><i class="fa-brands fa-twitter"></i></span>
                <span><i class="fa-brands fa-instagram"></i></span>
                <span><i class="fa-brands fa-pinterest"></i></span>
                <span><i class="fa-brands fa-youtube"></i></span>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12 order-lg-0 order-md-1 m0-lg-bottom m40-bottom">
              <div className="row">
                <div className="col-6 m0-bottom">
                  <h5>About US</h5>
                  <ul className="aboutUs m0 p0 m20-top">
                    <li>Search</li>
                    <li>New</li>
                    <li>About us</li>
                    <li>The Designers</li>
                    <li>Contect Us</li>
                    <li>Career @Sakhi</li>
                    <li>Feedback</li>
                  </ul>
                </div>
                <div className="col-6 m0-bottom">
                  <h5>POLICY</h5>
                  <ul className="aboutUs m0 p0 m20-top">
                    <li>Return Policy</li>
                    <li>Shipping Policy</li>
                    <li>Copyright</li>
                    <li>Terms of Use</li>
                    <li>Privacy And Security</li>
                    <li>FAQ</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-5 col-12">
              <h5>new seller</h5>
              <p className='p15-top'>Subscribe to receive updates, access to
                exclusive deals, and more.</p>
              <div className="emailSubmit">
                <input className='webEmail' type="email" placeholder="Enter your email" />
              </div>
              <div className="dark-btn m15-top">Subscribe</div>
            </div>
          </div>
          <div className="aboutFooter">
            <div className="row justify-content-between">
              <div className="col-md-5 col-12 text-capitalize">
                <p>©  {setting?.companyName}</p>
                <p> {setting?.companyName} All Rights Reserved</p>
              </div>
              <div className="col-md-5 col-12">
                <div className="payMents d-flex justify-content-between align-items-center">
                  <div><img src={Card1} alt="cards" /></div>
                  <div><img src={Card2} alt="cards" /></div>
                  <div><img src={Card3} alt="cards" /></div>
                  <div><img src={Card4} alt="cards" /></div>
                  <div><img src={Card5} alt="cards" /></div>
                  <div><img src={Card6} alt="cards" /></div>
                  <div><img src={Card7} alt="cards" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};


export default Footer;
