import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import NewCollection from './NewCollection';
import SareeCollection from './SareeCollection';
import CategoryCollection from './CategoryCollection';
import WeddingCollection from './WeddingCollection';
import BudgetCollection from './BudgetCollection';
import Policy from '../components/Policy';
import Service from '../components/Service';
import Footer from '../globle/Footer';
import Header from '../globle/Header';
import FebricCollection from './FebricCollection';
import ColorCollection from './ColorCollection';
import { useDispatch, useSelector } from 'react-redux';
import { productAttribute } from '../../redux/slice/extraSlice';
import { categoryGet } from '../../redux/slice/categorySlice';
import { budgetGet } from '../../redux/slice/budgetSlice';
import $ from "jquery"


const Collection = () => {
  return (
    <div>
      <Header />
      <Routes>
        <Route path="/" element={<SareeCollection />} />
        <Route path="/newCollection" element={<NewCollection />} />
        <Route path="/weddingCollection" element={<WeddingCollection />} />
        <Route path="/sareeCategory" element={<CategoryCollection />} />
        <Route path="/rang" element={<BudgetCollection />} />
        <Route path="/febric" element={<FebricCollection />} />
        <Route path="/color" element={<ColorCollection />} />
      </Routes>
      <Policy />
      <Service />
      <Footer />
    </div>
  );
};


export default Collection;

export const ProductFilter = ({ setFilter, tag }) => {
  // filter data

  const dispatch = useDispatch()

  const { category } = useSelector((state) => state.category);
  const { colorDetails, febricDetails } = useSelector((state) => state.extra);
  const { budget } = useSelector((state) => state.budget)
  const colorDetail = ['Red', 'Blue', 'Green', 'Yellow']

  useEffect(() => {
    dispatch(productAttribute());
    dispatch(categoryGet())
    dispatch(budgetGet())
  }, []);


  const [categoryFilter, setCategoryFilter] = useState("")
  const [priceFilter, setPriceFilter] = useState("")
  const [febricFilter, setFebricFilter] = useState("")
  const [colorFilter, setColorFilter] = useState("")

  useEffect(() => {
    setFilter({ categoryFilter, priceFilter, febricFilter, colorFilter })
  }, [categoryFilter, priceFilter, febricFilter, colorFilter]);

  const [colorFilterShow, setColorFilterShow] = useState("")
  const handleColor = (data) => {
    const newColor = data?.split("#")
    setColorFilter(`%23${newColor[1]}`)
    setColorFilterShow(data)
  }

  return (
    <>
      <FilterScript />
      <div className="filterIcon d-lg-none"><i class="ri-sound-module-line"></i></div>
      <div className="filterBg d-lg-none"></div>
      <div className="mainFilterPart">
        <h3 className='p-2 border-bottom-second-1 m0'>Filter</h3>
        <div className="startFilter">
          {
            tag != "category" && (
              <div className="allFilter categoryFilter">
                <h5>Category</h5>
                <ul className="mainFilter">
                  {
                    category.length > 0 && category?.map((res) => {
                      return <li className={`text-capitalize ${categoryFilter == res._id && "filterActive"}`} onClick={() => setCategoryFilter(res._id)}>{res.categoryName}</li>
                    })
                  }
                  <li onClick={() => setCategoryFilter("")}><i class="ri-close-circle-line"></i></li>
                </ul>
              </div>
            )
          }

          {
            tag != "budget" && (

              <div className="allFilter priceFilter">
                <h5>Price</h5>
                <ul className="mainFilter">
                  {
                    budget.length > 0 && budget?.map((res) => {
                      return <li className={`text-capitalize ${priceFilter == res.budget && "filterActive"}`} onClick={() => setPriceFilter(res.budget)}>Under ₹{res.budget}</li>
                    })
                  }
                  <li onClick={() => setPriceFilter("")}><i class="ri-close-circle-line"></i></li>
                </ul>
              </div>
            )
          }
          {
            tag != "color" && (
              <div className="allFilter colorFilter">
                <h5>Color</h5>
                <ul className="mainFilter">
                  {
                    colorDetails.length > 0 && colorDetails?.map((res) => {
                      return <li className={`text-capitalize p5-x ${colorFilterShow == res && "filterActive"}`} onClick={() => handleColor(res)}>
                        <div className='height-23 width-23 rounded-2' style={{ backgroundColor: res }}></div>
                      </li>
                    })
                  }
                  <li onClick={() => setColorFilter("")}><i class="ri-close-circle-line"></i></li>
                </ul>
              </div>
            )
          }
          {
            tag != "febric" && (
              <div className="allFilter febricFilter">
                <h5>Febric</h5>
                <ul className="mainFilter">
                  {
                    febricDetails.length > 0 && febricDetails?.map((res) => {
                      return <li className={`text-capitalize ${febricFilter == res && "filterActive"}`} onClick={() => setFebricFilter(res)}>{res}</li>
                    })
                  }
                  <li onClick={() => setFebricFilter("")}><i class="ri-close-circle-line"></i></li>
                </ul>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export const FilterScript = () => {

  useEffect(() => {

    const handleFilterToggle = () => {
      $(".mainFilterPart").toggleClass("filterOut")
      $(".filterBg").toggleClass("filterOut")
    }
    $(".filterIcon").on("click", handleFilterToggle);

    const handleFilterClose = () => {
      $(".mainFilterPart").removeClass("filterOut")
      $(".filterBg").removeClass("filterOut")
    }
    $(".filterBg").on("click", handleFilterClose);

    return () => {
      $(".filterIcon").off("click", handleFilterToggle);
    };
  }, []);

  return null
}