import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../extra/Input";
import Button from "../extra/Button";
import { objectToFormData, submitData } from "../utils/fuction";
import { login } from "../../redux/slice/userAuthSlice";
import LoginImage from "../../assets/images/loginImage.png"
import Header from "./Header";
import Poster2 from "../extra/Poster2";
import Service from "../components/Service";
import Footer from "./Footer";

const Login = (props) => {
  let navigate = useNavigate();
  const dispatch = useDispatch()

  const isAuth = useSelector((state) => state.userAuth.isAuth);
  useEffect(() => {
    isAuth && navigate("/");
  }, [isAuth]);



  const handleSubmit = async (e) => {
    const loginData = submitData(e);
    if (loginData) {
      const mainLogin = { ...loginData, loginType: 0 }
      // console.log("loginData", loginData);
      if (mainLogin) {
        let response = await dispatch(login(mainLogin))
        response.status && navigate("/");
      }
    }
  };


  return (
    <>
      <Header />
      <div className="mainLoginPage m40-top">
        <div className="loginDiv">
          <div className="container">
            <Poster2 title={`Login`} />
            <div className="row justify-content-center align-items-center">
              {/* <div className="col-lg-6 d-lg-block d-none">
                <img src={LoginImage} alt="" />
              </div> */}
              <div className="col-lg-4 col-md-7 col-12">
                <div className="loginPage text-center">
                  <form onSubmit={handleSubmit} id="loginForm">
                    <div className="loginInput">
                      <div className="row">
                        <div className="col-12">
                          <Input
                            type={`text`}
                            id={`email`}
                            name={`email`}
                            label={`Email`}
                            errorMessage={`Enter Email`}
                          />
                        </div>
                        <div className="col-12">
                          <Input
                            type={`text`}
                            id={`password`}
                            name={`password`}
                            label={`Password`}
                            errorMessage={`Enter Password`}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="loginButton">
                      <Button
                        type={`submit`}
                        className={`bg-second text-light cursor-pointer m10-top m-auto rounded-0 p40-x`}
                        text={`LOG IN`}
                      />
                    </div>
                    <div className="loginFooter m30-top">
                      <div className="forgetPassword themeFont">
                        <span>New Customer?</span>
                        <span> <Link to={`/signup`} className="text-second">Create New Account</Link></span>
                      </div>
                      <div className="forgetPassword themeFont text-second m5-top">
                        Forgot password
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Login;

export const Signup = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const handleSubmit = async (e) => {
    const loginData = submitData(e);
    if (loginData) {
      const mainLogin = { ...loginData, loginType: 1 }
      const formData = objectToFormData(mainLogin);
      if (formData) {
        let response = await dispatch(login(formData)).unwrap();
        response.status && navigate("/login");
      }
    }
  }
  return (
    <>
      <Header />
      <div className="mainSignupPage">
        <div className="container">
          <Poster2 title={`Sign Up`} />
          <div className="row justify-content-center">
            <div className="col-lg-6 col-md-9">
              <div className="signPage pt-3 m-auto text-center">
                <form onSubmit={handleSubmit} id="signupForm">
                  <div className="signInput">
                    <div className="row">
                      <div className="col-6">
                        <Input
                          type={`text`}
                          id={`email`}
                          name={`email`}
                          label={`Email`}
                          errorMessage={`Enter Email`}
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          type={`text`}
                          id={`password`}
                          name={`password`}
                          label={`Password`}
                          errorMessage={`Enter Password`}
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          type={`text`}
                          id={`name`}
                          name={`name`}
                          label={`Name`}
                          errorMessage={`Enter Name`}
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          type={`number`}
                          id={`mobileNo`}
                          name={`mobileNo`}
                          label={`Mobile Number`}
                          errorMessage={`Enter Mobile Number`}
                        />
                      </div>
                      <div className="col-6">
                        <Input
                          type={`file`}
                          id={`profileImage`}
                          name={`profileImage`}
                          label={`Profile Image`}
                          errorMessage={`Enter Profile Image`}
                        />
                      </div>

                      <div className="col-3 align-self-center">
                        <Input
                          type={`radio`}
                          id={`male`}
                          name={`gender`}
                          label={`Male`}
                          value={`male`}
                        />
                      </div>
                      <div className="col-3  align-self-center">
                        <Input
                          type={`radio`}
                          id={`female`}
                          name={`gender`}
                          label={`Female`}
                          value={`female`}
                        />
                      </div>
                    </div>
                    <div className="forgetPassword themeFont  m10-bottom">
                      <span>Have You Account? </span>
                      <span><Link to={`/login`} className="text-second">Login</Link></span>
                    </div>
                  </div>
                  <div className="loginButton">
                    <Button
                      type={`submit`}
                      className={`bg-second m-auto text-light cursor-pointer m10-top p40-x rounded-0`}
                      text={`SIGN UP`}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}