import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../pages/extra/toast";

const initialState = {
  payment: [],
  isSkeleton: false,
};
export const checkoutPayment = createAsyncThunk(
  "payment/checkout",
  async (payload) => {
    return apiInstance.post(`payment/checkout`, payload);
  }
);
export const getRazorKey = createAsyncThunk("payment/getRazorKey", async () => {
  return apiInstance.get(`payment/getRazorKey`);
});
export const paymentVerification = createAsyncThunk(
  "payment/paymentVerification",
  async (payload) => {
    return apiInstance.post(`payment/paymentVerification`, payload);
  }
);

const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // checkoutPayment
    builder.addCase(checkoutPayment.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(checkoutPayment.fulfilled, (state, action) => {
    });
    builder.addCase(checkoutPayment.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // paymentVerification
    builder.addCase(paymentVerification.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(paymentVerification.fulfilled, (state, action) => {
      console.log("action.payload payment", action.payload);
    });
    builder.addCase(paymentVerification.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default paymentSlice.reducer;
