import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Saree from "../assets/images/saree1.png"
import { closeDialog } from '../redux/slice/dialogSlice';
import { baseURL } from './utils/config';

const OrderShow = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { dialogueData } = useSelector(
    (state) => state.dialogue
  );



  const statusLabels = {
    "NEW": 1,
    "INVOICED": 1,
    "PICKUP ERROR": 2,
    "PICKUP SCHEDULED": 2,
    "PICKUP GENERATED": 2,
    "PICKED UP": 2,
    "IN TRANSIT": 2,
    "REACHED AT DESTINATION HUB": 2,
    "FDM PREPARED": 2,
    "OUT FOR DELIVERY": 3,
    "DELIVERED": 4,
    "CANCELED": 5
  };

  const status = dialogueData?.data?.status?.toUpperCase() || "";

  const finalStatus = statusLabels[status] || "Confirm";


  return (

    <div className="dialog">

      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-5 col-md-8 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8"></div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <div className="orderDetails border-bottom-gray2-1  p10-bottom">
                <div className="row">
                  <div className="col-md-6 col-12">
                    <div className="orderAdress fs-lg-18 fs-md-16 fs-14">
                      <h3 className='fs-lg-24 fs-md-20 fs-18'>Delivery Address</h3>
                      <div className='fw-600'>{dialogueData?.address?.fullName}  <span className='bg-gray text-light p3 fs-lg-16 fs-md-14 fs-12 m5-left'>{dialogueData?.address?.type}</span></div>
                      <div className='text-darkGray'>{dialogueData?.address?.details}</div>
                      <div className='text-success m3-top'>+91 {dialogueData?.address?.phone}</div>


                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="orderText d-flex fs-lg-18 fs-md-16 fs-14 m30-top">
                    <div className="orderImage m20-right hw-lg-100 hw-80">
                      <img src={baseURL + dialogueData?.productId?.productImage[0]} alt="Order Image" width={`100%`} />
                    </div>
                    <div>
                      <div className="orderId">
                        <span className='fw-600'>Order ID :</span> <span className='text-darkGray'>{dialogueData?.orderId}</span>
                      </div>
                      <div className="orderTitle m10-top fw-600">{dialogueData?.productId?.title}</div>
                      <div className="orderQut m5-top">
                        <span>Quantity :</span> <span>{dialogueData?.productCount}</span>
                      </div>
                      <h4 className="orderPrice m5-top fs-lg-24 fs-md-20 fs-18">₹{parseInt(dialogueData?.productId?.price) * parseInt(dialogueData?.productCount)}</h4>
                      {
                        dialogueData?.data?.awb_data?.awb != "" && (
                          <span><a className='trackingLink text-links fw-600' href={`https://shiprocket.co/tracking/${dialogueData?.data?.awb_data?.awb}`} target='_blank'>Track your order</a></span>
                        )
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="orderTrakeStatus m30-top">
                    <OrderTrack
                      title={`Order Places`}
                      dec={`Your Order Places Successfully`}
                      icon={`ri-shopping-bag-3-line`}
                      status={finalStatus >= 1 ? true : false}
                    />

                    <OrderTrack
                      title={`Order Confirm`}
                      dec={`Shipment yet to reach hub nearest to you.`}
                      icon={`ri-shield-check-line`}
                      status={finalStatus >= 2 ? true : false}
                    />

                    {finalStatus != 5 && (
                      <OrderTrack
                        title={`Out of Delivery`}
                        icon={`ri-truck-line`}
                        status={finalStatus >= 3 ? true : false}
                      />
                    )}

                    {finalStatus === 5 ? (
                      <OrderTrack
                        title={`Order Cancel`}
                        icon={`ri-close-circle-line`}
                        status={finalStatus >= 5 ? true : false}
                        last={true}
                      />
                    ) :
                      finalStatus === 6 ? (
                        <OrderTrack
                          title={`Order Return`}
                          icon={`ri-text-wrap`}
                          status={finalStatus >= 6 ? true : false}
                          last={true}
                        />
                      ) : (
                        <OrderTrack
                          title={`Delivery Received`}
                          icon={`ri-map-pin-line`}
                          status={finalStatus >= 4 ? true : false}
                          last={true}
                        />
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderShow;


export const OrderTrack = ({ title, dec, icon, status, last }) => {
  return (
    <div className="orderPlace d-flex align-items-start">
      <div className="statusIcon m20-right">
        {
          status ? <i className="ri-checkbox-circle-fill fs-lg-22 fs-md-20 fs-18 text-success"></i> : <i className="ri-checkbox-blank-circle-fill fs-lg-22 fs-md-20 fs-18 text-gray"></i>
        }
        {!last && <div className={`orderLine ${status ? "bg-success" : "bg-gray"}`}></div>}
      </div>
      <div className={`d-flex align-items-center ${!status && "text-gray"}`}>
        <div className="orderIcon m10-right">
          <i className={`${icon} fs-lg-34 fs-md-30 fs-26`}></i>
        </div>
        <div className="orderTrackText">
          <h3 className='fs-lg-24 fs-md-20 fs-18 m0-bottom'>{title}</h3>
          {status && <p className='text-success fs-lg-18 fs-md-16 fs-14'>{dec}</p>}
        </div>
      </div>
    </div>
  )
}