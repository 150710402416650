import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { editData, objectToFormData, submitData } from "../utils/fuction";
import Input from "../extra/Input";
import Button from "../extra/Button";
import { useEffect, useState } from "react";
import { updateUser } from "../../redux/slice/userAuthSlice";
import Title from "../extra/Title";

const UserInfo = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const { loginUser, isAuth } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);
  const realUser = loginUser || parsedSessionUserId

  const [isEdit, setIsEdit] = useState(true)

  useEffect(() => {
    if (parsedSessionUserId) {
      editData(parsedSessionUserId);
    }
  }, []);

  const handleSubmit = async (e) => {
    const loginData = submitData(e);
    if (loginData) {
      const mainLogin = { ...loginData, loginType: 1 }
      const formData = objectToFormData(mainLogin);
      if (formData) {
        await dispatch(updateUser({ formData, userId: realUser._id })).unwrap();
        setIsEdit(true)
      }
    }
  }
  return (
    <>

      <div className="mainSignupPage  m30-top">
        <div className="">
          <Title name={`Personal Info`} className={`fw-500 p10-bottom `} />
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="signPage m-auto text-center p0">
                <form onSubmit={handleSubmit} id="profileInfoForm">
                  <div className="signInput">
                    <div className="row">
                      <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                        <Input
                          type={`text`}
                          id={`email`}
                          name={`email`}
                          label={`Email`}
                          errorMessage={`Enter Email`}
                          disabled={isEdit}
                        />
                      </div>
                      <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                        <Input
                          type={`text`}
                          id={`name`}
                          name={`name`}
                          label={`Name`}
                          errorMessage={`Enter Name`}
                          disabled={isEdit}
                        />
                      </div>
                      <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                        <Input
                          type={`number`}
                          id={`mobileNo`}
                          name={`mobileNo`}
                          label={`Mobile Number`}
                          errorMessage={`Enter Mobile Number`}
                          disabled={isEdit}
                        />
                      </div>
                      <div className="col-3 align-self-center">
                        <Input
                          type={`radio`}
                          id={`male`}
                          name={`gender`}
                          label={`Male`}
                          value={`male`}
                          disabled={isEdit}
                        />
                      </div>
                      <div className="col-3  align-self-center">
                        <Input
                          type={`radio`}
                          id={`female`}
                          name={`gender`}
                          label={`Female`}
                          value={`female`}
                          disabled={isEdit}
                        />
                      </div>
                      <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                        <Input
                          type={`file`}
                          id={`profileImage`}
                          name={`profileImage`}
                          label={`Profile Image`}
                          errorMessage={`Enter Profile Image`}
                          disabled={isEdit}
                        />
                      </div>

                    </div>
                  </div>
                  <div className="loginButton">
                    {
                      !isEdit && (
                        <>
                          <Button
                            type={`submit`}
                            className={`bg-second text-light cursor-pointer m10-top m10-right p40-x rounded-0`}
                            text={`Update Profile`}
                          />
                          <Button
                            type={`button`}
                            className={`bg-danger text-light cursor-pointer m10-top p40-x rounded-0`}
                            text={`Cancle`}
                            onClick={() => setIsEdit(true)}
                          />

                        </>
                      )

                    }
                    {
                      isEdit &&
                      <Button
                        type={`button`}
                        className={`bg-second text-light cursor-pointer m10-top p40-x rounded-0`}
                        text={`Edit Profile`}
                        onClick={() => setIsEdit(false)}
                      />
                    }

                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UserInfo;