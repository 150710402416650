import SareeDetails from "./components/SareeDetails";
import BestSeller from "./components/BestSeller";
import Banner from "./extra/Banner";
import Service from "./components/Service";
import Header from "./globle/Header";
import Footer from "./globle/Footer";

const SareeProfile = () => {
  return (
    <div className="scrollPage" id="SareeProfileMain">
      <Header />
      <section id="SareeProfile">
        {/* <Banner /> */}
        <SareeDetails />
        <BestSeller/>
        <Service />
      </section>
      <Footer />
    </div>
  );
};

export default SareeProfile;
