import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../pages/extra/toast";

const initialState = {
  wishlist: [],
  isSkeleton: false,
};
export const showWishlist = createAsyncThunk("wishlist/show", async (payload) => {
  return apiInstance.get(`wishlist/show?userId=${payload}`);
});
export const removeWishlist = createAsyncThunk("wishlist/delete", async (payload) => {
  return apiInstance.delete(`wishlist/delete?wishlistId=${payload}`);
});

const wishlistSlice = createSlice({
  name: "wishlistSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // showWishlist
    builder.addCase(showWishlist.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(showWishlist.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.wishlist = action.payload.wishlist;
        state.isSkeleton = false;
      }

    });
    builder.addCase(showWishlist.rejected, (state, action) => {
      state.isSkeleton = false;
    });


    builder.addCase(removeWishlist.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.wishlist = state.wishlist.filter((wishlist) => wishlist._id !== action.meta.arg);
        setToast("success", action.payload.message)
      }
    });

  },
});
export default wishlistSlice.reducer;

