import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  colorDetails: [],
  febricDetails: [],
  isSkeleton: false,
};

export const productAttribute = createAsyncThunk("product/getAllAttribute", async () => {
  return apiInstance.get(`product/getAllAttribute`);
});


const extraSlice = createSlice({
  name: "extraSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {


    // product attrabute [febric,color]
    builder.addCase(productAttribute.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(productAttribute.fulfilled, (state, action) => {
      state.colorDetails = action.payload.color;
      state.febricDetails = action.payload.febric;
      state.isSkeleton = false;

    });
    builder.addCase(productAttribute.rejected, (state, action) => {
      state.isSkeleton = false;
    });

  },
});
export default extraSlice.reducer;

