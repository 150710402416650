import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../redux/slice/dialogSlice";
import Input, { Select, Textarea } from "./extra/Input";
import Button from "./extra/Button";
import { editData, submitData } from "./utils/fuction";
import {
  addAddress,
  deleteAddress,
  updateAddress,
} from "../redux/slice/userAuthSlice";

const AddressDialogue = ({ data, setData }) => {
  const { dialogueData } = useSelector((state) => state.dialogue);

  useEffect(() => {
    if (dialogueData) {
      editData(dialogueData);
    }
  }, [dialogueData]);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const handleSubmit = (e) => {
    const addData = submitData(e);
    console.log("addData", addData);
    if (addData) {
      if (dialogueData) {
        dispatch(
          updateAddress({
            ...addData,
            userId: parsedSessionUserId._id,
            addressId: dialogueData._id,
          })
        );
      } else {
        dispatch(addAddress({ ...addData, userId: parsedSessionUserId._id }));
      }
    }
    dispatch(closeDialog());
  };

  const dispatch = useDispatch();
  return (
    <div className="dialog">
      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-md-9 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-second m0">Add Address</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form id="checkoutForm" onSubmit={handleSubmit}>
                <AddressForm />
                <div className="primeButton">
                  <button type="submit">Add Address</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressDialogue;

// ======================== AddressForm =======================
export const AddressForm = () => {
  const option = ["India", "pakistan", "Bangladsh", "chiana", "Khalistan"];
  return (
    <div className="m40-top border-bottom-gray2-1 p30-bottom">
      <div className="row">
        <div className="col-6">
          <Input
            type={`text`}
            id={`fullName`}
            label={`Full Name`}
            placeholder={`Full Name`}
            name={`fullName`}
            errorMessage={`Enter Full Name`}
          />
        </div>
        <div className="col-6">
          <Input
            type={`number`}
            id={`phone`}
            name={`phone`}
            label={`Mobile Number`}
            placeholder={`Mobile Number`}
            errorMessage={`Enter Mobile Number`}
          />
        </div>
        <div className="col-12">
          <Textarea
            id={`socName`}
            name={`socName`}
            label={`Address`}
            row={3}
            errorMessage={`Enter Address`}
          />
        </div>
        <div className="col-4">
          <Input
            type={`text`}
            id={`city`}
            name={`city`}
            label={`City`}
            placeholder={`City`}
            errorMessage={`Enter City`}
          />
        </div>
        <div className="col-4">
          <Input
            type={`text`}
            id={`state`}
            name={`state`}
            label={`State`}
            placeholder={`State`}
            errorMessage={`Enter State`}
          />
        </div>
        <div className="col-4 align-self-center">
          <div className="row">
            <div className="col-6">
              <Input
                type={`radio`}
                id={`home`}
                name={`type`}
                label={`Home`}
                value={`home`}
                inputClass={`justify-content-center`}
              />
            </div>
            <div className="col-6">
              <Input
                type={`radio`}
                id={`office`}
                name={`type`}
                label={`Office`}
                value={`office`}
              />
            </div>
          </div>
        </div>
        <div className="col-6">
          <Input
            type={`number`}
            id={`pincode`}
            name={`pincode`}
            label={`Pincode`}
            placeholder={`0 0 0 0 0 0`}
            errorMessage={`Enter pincode`}
            // validation={`/^(1[89]|[2-9]\d)$/gm`}
            // validationError={`Valid Only 18+`}
          />
        </div>
        <div className="col-6">
          <Select
            option={option}
            id={`country`}
            name={`country`}
            label={`Country`}
            className={`inputSelect`}
            placeholder={`Select Country`}
            errorMessage={`Select Country`}
          />
        </div>
      </div>
    </div>
  );
};
