import Swal from "sweetalert2";

export const warning = (title, text, btn, icon, color) => {
  return Swal.fire({
    title: title || "Are you sure?",
    text: text || "You won't be able to revert this!",
    iconHtml: `<i class='${icon || `ri-delete-bin-5-line`} text-${
      color || "danger"
    } '></i>`,
    customClass: {
      icon: "no-border",
    },
    showCancelButton: true,
    confirmButtonText: btn || "Delete",
    customClass: {
      confirmButton: `btn bg-${color || "danger"} text-light m15-right`,
      cancelButton: "btn bg-darkGray text-light",
    },
    buttonsStyling: false,
  });
};
