import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../pages/extra/toast";

const initialState = {
  isSkeleton: false,
};

export const ratingCreate = createAsyncThunk("rating/create", async (payload) => {
  return apiInstance.post(`rating/create`, payload);
});

const reviewSlice = createSlice({
  name: "reviewSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // ratingCreate
    builder.addCase(ratingCreate.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(ratingCreate.fulfilled, (state, action) => {
      debugger
      if (action?.payload?.status) {
        setToast("success", action.payload.message)
      } else {
        setToast("error", action.payload.message)
      }
    });
    builder.addCase(ratingCreate.rejected, (state, action) => {
      state.isSkeleton = false;
    });

  },
});
export default reviewSlice.reducer;

