import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { submitData } from "../utils/fuction";
import { closeDialog, openDialog } from "../../redux/slice/dialogSlice";
import { addAddress } from "../../redux/slice/userAuthSlice";
import Title from "../extra/Title";
import { UserAddress } from "../Checkout";
import AddressDialogue, { AddressForm } from "../AddressDialogue";

const UserProfileAddress = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location", location);
  const { loginUser, isAuth } = useSelector((state) => state.userAuth);
  const { dialogue, dialogueType } = useSelector((state) => state.dialogue);

  // get address
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);
  const userAddress = loginUser.address || parsedSessionUserId.address;

  // Crud Address
  const handleSubmit = (e) => {
    const addData = submitData(e);
    console.log("addData", addData);
    if (addData) {
      dispatch(addAddress({ ...addData, userId: parsedSessionUserId._id }));
    }
    dispatch(closeDialog());
  };

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(location?.state));
  }, [location]);
  const [addressNumber, setAddressNumber] = useState(0);
  useEffect(() => {
    console.log("addressNumber", addressNumber);
  }, [addressNumber]);

  return (
    <>
      <section id="Checkout">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="d-flex justify-content-between">
                <Title
                  name={`Manage Address`}
                  className={`fw-500 p10-bottom`}
                />
              </div>
              <div className="mianAddress">
                <div className="row">
                  {userAddress?.length > 0 ? (
                    <>
                      {userAddress?.map((res, i) => {
                        return (
                          <div className="col-xl-6 col-md-12 col-sm-6 col-12">
                            <UserAddress
                              data={res}
                              ids={i}
                              key={i}
                              userId={parsedSessionUserId}
                              setAddressNumber={setAddressNumber}
                              addressNumber={addressNumber}
                              isSelect={true}
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <form id="checkoutForm" onSubmit={handleSubmit}>
                      <AddressForm />
                      <div className="primeButton">
                        <button type="submit">Add Address-</button>
                      </div>
                    </form>
                  )}
                </div>
                {userAddress?.length > 0 && (
                  <div className="primeButton">
                    <button
                      className="p10-x"
                      onClick={() => {
                        dispatch(openDialog({ type: "address" }));
                      }}
                    >
                      Add Address
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {dialogue && dialogueType === "address" && <AddressDialogue />}
      </section>
    </>
  );
};

export default UserProfileAddress;
