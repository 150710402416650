import React, { useEffect } from 'react';
import Header from './globle/Header';
import Footer from './globle/Footer';
import Banner from './extra/Banner';
import Title from './extra/Title';
import Poster from './components/Poster';
import Poster2 from './extra/Poster2';
import Service from './components/Service';
import Logo from "../assets/images/logo.png"
import { Link } from 'react-router-dom';
import Input, { Textarea } from './extra/Input';
import Button from './extra/Button';
import { settingGet } from '../redux/slice/settingSlice';
import { useDispatch, useSelector } from 'react-redux';
const Contact = () => {

  const dispatch = useDispatch()
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(settingGet())
  }, []);

  return (

    <>
      <Header />

      <div className="mainAbout m40-top">
        <div className="container">
          <Poster2 title={`Contact Us`} />

          <div className="myContact m20-top fs-md-20 fs-sm-16 fs-14  text-center">
            <div className="">
              <div className="row justify-content-center">
                <div className="col-md-4 col-sm-6 col-12">
                  <div className="logo m-auto m30-bottom">
                    <Link to="/"><img src={Logo} alt="THE VINI" /></Link>
                  </div>

                  <div className="mainStape fw-600">
                    <div className="stape m35-bottom">
                      <h2 className='text-second text-decoration-underline m15-bottom fs-md-34 fs-sm-28 fs-22 '>Contact Details</h2>
                      <div>
                        <span>Call Us :</span>
                        <span className='text-second'> {setting?.contact}</span>
                      </div>
                      <div>
                        <span>Email Us :</span>
                        <span className='text-second'> {setting?.email}</span>
                      </div>
                    </div>
                    <div className="stape m35-bottom">
                      <h2 className='text-second text-decoration-underline m15-bottom fs-md-34 fs-sm-28 fs-22'>Shop Address</h2>
                      <div>
                        <pre className='font-prime'>{setting?.address}</pre>
                      </div>
                    </div>
                    <div className="stape m35-bottom">
                      <h2 className='text-second text-decoration-underline m15-bottom fs-md-34 fs-sm-28 fs-22'>Shop Timing</h2>
                      <div>
                        <pre className='font-prime'>{setting?.shopTime} </pre>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="col-md-4 col-sm-6 col-12">

                  <div className="sendMessage p40-x">

                    <form onSubmit={`#`} id="contactMsg">
                      <div className="loginInput">
                        <div className="row">
                          <div className="col-12">
                            <Input
                              type={`text`}
                              id={`name`}
                              name={`name`}
                              label={`Enter Name`}
                              errorMessage={`Enter Name`}
                            />
                          </div>
                          <div className="col-12">
                            <Input
                              type={`text`}
                              id={`email`}
                              name={`email`}
                              label={`Enter Email`}
                              errorMessage={`Enter Email`}
                            />
                          </div>
                          <div className="col-12">
                            <Input
                              type={`number`}
                              id={`phoneNo`}
                              name={`phoneNo`}
                              label={`Phone Number`}
                              errorMessage={`Enter Number`}
                            />
                          </div>
                          <div className="col-12">
                            <Textarea
                              type={`number`}
                              id={`massage`}
                              name={`massage`}
                              label={`Message`}
                              errorMessage={`Enter Message`}
                              row={7}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="loginButton">
                        <Button
                          type={`submit`}
                          className={`bg-second w-100 text-light cursor-pointer m10-top`}
                          text={`Send Message`}
                        />
                      </div>
                    </form>

                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Service />
      <Footer />
    </>
  );
}

export default Contact;
