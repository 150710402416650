import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../pages/extra/toast";

const initialState = {
  order: [],
  isSkeleton: false,
  isLoader: false,
};

export const userOrder = createAsyncThunk(
  "order/userOrder",
  async (payload) => {
    return apiInstance.get(`order/userOrder?userId=${payload}`);
  }
);

export const orderSubmit = createAsyncThunk(
  "order/submitOrder",
  async (payload) => {
    return apiInstance.post(`order/submitOrder`, payload);
  }
);

export const invoiceDownload = createAsyncThunk(
  "order/getMyInvoice",
  async (payload) => {
    return apiInstance.post(`order/getMyInvoice`, payload);
  }
);

const orderSlice = createSlice({
  name: "orderSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // userOrder
    builder.addCase(userOrder.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(userOrder.fulfilled, (state, action) => {
      if (action?.payload?.status) {
        state.order = action.payload.order;
        state.isSkeleton = false;
        console.log("action.payload", action.payload);
      }
    });
    builder.addCase(userOrder.rejected, (state, action) => {
      state.isSkeleton = false;
    });
    // orderSubmit
    builder.addCase(orderSubmit.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(orderSubmit.fulfilled, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(orderSubmit.rejected, (state, action) => {
      state.isLoader = false;
    });
    // invoiceDownload
    builder.addCase(invoiceDownload.pending, (state, action) => {
      state.isLoader = true;
    });
    builder.addCase(invoiceDownload.fulfilled, (state, action) => {
      state.isLoader = false;
    });
    builder.addCase(invoiceDownload.rejected, (state, action) => {
      state.isLoader = false;
    });
  },
});
export default orderSlice.reducer;
