import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToWishlist, productDetails } from "../../redux/slice/productSlice";
import { baseURL } from "../utils/config";
import { useLocation, useNavigate } from "react-router-dom";
import { addCart } from "../../redux/slice/cartSlice";
import { productCareGet } from "../../redux/slice/productCareSlice";

const SareeDetails = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  console.log("location", location);

  const splitPath = location?.pathname?.split("/");

  const { loginUser, isAuth } = useSelector((state) => state.userAuth);
  const { productShow, isSkeleton } = useSelector((state) => state.product);
  const { cartLoader } = useSelector((state) => state.cart);
  const { setting } = useSelector((state) => state.setting);
  const { productCare } = useSelector((state) => state.productCare);

  // Get Index Of Product ID
  const productMaterId = localStorage.getItem("productId");
  const masterProductId = location?.state?.productId || productMaterId;
  const masterCount = productShow?.allProduct?.findIndex(
    (product) => product._id === masterProductId
  );

  let getCount = localStorage.getItem("myCount");

  const [countArray, setCountArray] = useState(getCount);

  const [imageShow, setImageShow] = useState(0);
  const images = productShow?.allProduct?.[countArray]?.productImage;
  const wishIcon = productShow?.allProduct?.[countArray]?.wishlist;

  const oldCode = localStorage.getItem("productCode");
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  useEffect(() => {
    if (masterCount != -1) {
      if (masterCount >= 0) {
        localStorage.setItem("myCount", masterCount);
        setCountArray(masterCount);
      }
    }
  }, [location?.state?.productId, masterCount]);

  useEffect(() => {
    dispatch(
      productDetails({
        productCode: splitPath[2] || oldCode,
        userId: loginUser?._id || parsedSessionUserId?._id,
      })
    );
  }, [loginUser?._id, location?.state?.productId, location?.pathname]);

  useEffect(() => {
    if (images) {
      setImageShow(images[0] || "");
    }
  }, [countArray, images, location?.state?.productId]);
  useEffect(() => {
    dispatch(productCareGet());
  }, []);

  const [count, setCount] = useState(1);

  const navigate = useNavigate();

  const handleWishlist = (productId) => {
    if (isAuth) {
      dispatch(addToWishlist({ userId: loginUser?._id, productId }));
    } else {
      navigate("/login");
    }
  };

  const handleAddToCart = (cnt) => {
    if (isAuth) {
      dispatch(
        addCart({
          productId: productShow?.allProduct?.[countArray]?._id,
          userId: loginUser?._id || parsedSessionUserId?._id,
          productCount: cnt || 1,
        })
      );
    } else {
      navigate("/login");
    }
  };

  const handleCheckout = (productDetails, productCount) => {
    console.log("productDetails ======", productDetails);
    const userId = loginUser?._id || parsedSessionUserId?._id;
    const {
      discount,
      outOfStock,
      oldPrice,
      price,
      productCode,
      productImage,
      shippingCharge,
      title,
      length,
      breadth,
      height,
      weight,
      sku,
    } = productDetails;
    const finalCount = productCount * 1;
    const cart = {
      allProduct: [
        {
          _id: null,
          discount,
          outOfStock,
          oldPrice,
          price,
          productCode,
          productCount: finalCount,
          productId: productDetails.allProduct[countArray]._id,
          productImage: productDetails.allProduct[countArray].productImage,
          shippingCharge,
          title,
          userId,
          savePrice: oldPrice - price,
          finalOldPrice: oldPrice * finalCount,
          finalPrice: price * finalCount,
          finalSavePrice: (oldPrice - price) * finalCount,
          length,
          breadth,
          height,
          weight,
          sku,
        },
      ],
      oldPrice,
      price,
      finalOldPrice: oldPrice * finalCount,
      finalPrice: price * finalCount,
      finalSavePrice: (oldPrice - price) * finalCount,
      productCount: finalCount,
      totalProduct: 1,
    };
    if (isAuth) {
      localStorage.setItem("cart", JSON.stringify(cart));
      navigate("/checkout", { state: cart });
    } else {
      navigate("/login");
    }
  };

  const [details, setDetails] = useState(0);

  const today = new Date();
  const futureDate = new Date(today);
  futureDate.setDate(today.getDate() + parseInt(setting?.deliveryDay));

  const options = { day: "2-digit", month: "short" };
  const todayFormatted = today.toLocaleDateString("en-US", options);
  const futureDateFormatted = futureDate.toLocaleDateString("en-US", options);

  const [key, setKey] = useState(0); // Key to force re-render

  // Re-render the component when `data` changes
  useEffect(() => {
    setKey((prevKey) => prevKey + 1); // Update key to reset animation
  }, [loginUser?._id, location?.state?.productId, location?.pathname]);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Check this out!",
          text: "Here is an interesting page I found:",
          url: window.location.href,
        });
        console.log("Shared successfully!");
      } catch (error) {
        console.error("Error sharing:", error);
      }
    } else {
      alert("Web Share API is not supported in your browser.");
    }
  };

  return (
    <section id="SareeDetails">
      <div className="container border-bottom-gray-1 p20-bottom" key={key}>
        {/* Product Profile */}
        <div className="row justify-content-center">
          <div
            className={`col-lg-6 col-12 animate__animated animate__fadeInLeft`}
          >
            {!isSkeleton ? (
              <div className="sareePhotos">
                <div className="row m-auto justify-content-center">
                  <div className=" col-xl-3 order-xl-0 order-1">
                    <div className="sideImage mx-auto mt-xl-0 mt-3 d-flex flex-xl-column flex-row">
                      {images?.map((res) => (
                        <div className="optionImage skeleton">
                          <img
                            src={baseURL + res}
                            alt=""
                            onClick={() => setImageShow(res)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-9">
                    <div className="displayImage mx-auto bg-gray2 skeleton">
                      <img src={baseURL + imageShow} alt="" height={`100%`} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="sareePhotos">
                <div className="row m-auto justify-content-center">
                  <div className="col-xxl-2 col-xl-3 order-xl-0 order-1">
                    <div className="sideImage mx-auto mt-xl-0 mt-3 d-flex flex-xl-column flex-row">
                      {[...Array(5)].map((res) => (
                        <div className="optionImage skeleton">
                          <img />
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-9">
                    <div className="displayImage mx-auto skeleton bg-gray2">
                      <img />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-lg-6 col-sm-9 col-12 m0-lg-top m30-top animate__animated animate__fadeInRight">
            {!isSkeleton ? (
              <div className="sareeDetails">
                <div className="upperPart betBox">
                  <div className="">
                    <span className="rating bg-success text-light">
                      <span>
                        {productShow?.rating?.totalRating?.toFixed(1) || 0}
                      </span>{" "}
                      <i class="fa-solid fa-star ms-1"></i>
                    </span>
                    <span className="fs-14 text-gray ms-3">
                      ({productShow?.rating?.total || 0} review)
                    </span>
                  </div>
                  <div className="listIcon fs-20">
                    <span
                      className="me-4"
                      onClick={() =>
                        handleWishlist(
                          productShow?.allProduct?.[countArray]?._id
                        )
                      }
                    >
                      {wishIcon ? (
                        <i class="ri-heart-fill text-danger fs-24"></i>
                      ) : (
                        <i class="ri-heart-line fs-24"></i>
                      )}
                    </span>
                    <span onClick={handleShare}>
                      <i class="fa-regular fa-share-from-square"></i>
                    </span>
                  </div>
                </div>
                <div className="profileTitle mt-3">
                  <h3 className="fw-500">{productShow?.title}</h3>
                </div>
                <div className="pofileType">
                  <span className="text-gray me-1">Material :</span>
                  <span className="fw-500 text-capitalize">
                    {productShow?.categoryName}
                  </span>
                </div>
                <div className="profilePrice mt-3 d-flex align-items-center">
                  <span className="me-3">
                    <h2>₹ {productShow?.price}/- </h2>
                  </span>
                  <span>
                    <del className="text-gray">
                      ₹ {productShow?.oldPrice}/-{" "}
                    </del>
                  </span>
                  <span className="fw-700 ms-3 text-danger">
                    {productShow?.discount}% off
                  </span>
                </div>
                <div className="profileQuantity mt-4 d-flex align-items-center">
                  <span className="text-gray me-4">Quantity :</span>
                  <span>
                    <div className="mainCount">
                      <span
                        className="fs-20"
                        onClick={() => {
                          setCount(count <= 1 ? 1 : count - 1);
                        }}
                      >
                        -
                      </span>
                      <span className="fs-20 fw-700">
                        <input
                          type="text"
                          value={count}
                          onChange={(e) => {
                            setCount(e.target.value);
                          }}
                        />
                      </span>
                      <span
                        className="fs-20"
                        onClick={() => {
                          setCount(parseInt(count) + 1);
                        }}
                      >
                        +
                      </span>
                    </div>
                  </span>
                </div>
                <div className="profileColor mt-5 d-flex align-items-center">
                  <span className="text-gray me-4">Colors :</span>
                  <span className="colorPlate d-flex">
                    {productShow?.color?.map((res, i) => (
                      <span
                        style={{ backgroundColor: res }}
                        onClick={() => {
                          setCountArray(i);
                          localStorage.setItem("myCount", i);
                          localStorage.setItem(
                            "productId",
                            productShow?.allProduct[i]._id
                          );
                        }}
                        className={countArray == i && "round"}
                      ></span>
                    ))}
                  </span>
                  <span className="text-gray ms-3 fs-14">
                    {productShow?.allProduct?.[countArray]?.stock} in stock
                  </span>
                </div>
                <div className="deliveryDays m20-top fw-bold text-gray fs-sm-20 fs-16">
                  EXPECTED DELIVERY BETWEEN{" "}
                  <span className="text-second">{todayFormatted}</span> TO{" "}
                  <span className="text-second">{futureDateFormatted}</span>
                </div>
                {productShow?.allProduct?.[countArray]?.outOfStock ? (
                  <div className="themeBtn mt-5 d-flex justify-content-between w-xl-80">
                    <button
                      type="button"
                      className="m10-right border-danger-1 bg-transparent text-danger fw-bold"
                    >
                      Out of Stock
                    </button>
                  </div>
                ) : (
                  <div className="themeBtn mt-5 d-flex justify-content-between w-xl-80">
                    <button
                      type="button"
                      className="m10-right"
                      onClick={() => handleCheckout(productShow, count)}
                    >
                      Buy Now
                    </button>
                    <button
                      type="button"
                      className="m10-left"
                      onClick={() => handleAddToCart(count)}
                    >
                      {cartLoader ? (
                        <span class="cartLoader"></span>
                      ) : (
                        "Add To Cart"
                      )}{" "}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <div className="sareeDetails">
                <div className="upperPart skeleton height-20"></div>
                <div className="profileTitle mt-3 skeleton height-30 w-40"></div>
                <div className="pofileType mt-1 skeleton height-15 w-20"></div>
                <div className="profilePrice mt-5 skeleton height-40 w-70"></div>
                <div className="profileQuantity mt-4 skeleton height-40 w-50"></div>
                <div className="profileColor mt-5 skeleton height-50 w-30"></div>
                <div className="themeBtn mt-5 skeleton height-50 w-100"> </div>
              </div>
            )}
          </div>
        </div>

        {/*  Our Servecies */}
        <div className="row font-second  m20-top animate__animated animate__fadeInUp">
          <ProvideService
            icon={`fa-solid fa-truck-fast`}
            title={`Free Shipping`}
            detail={`From all order over ₹ 100`}
          />
          <ProvideService
            icon={`fa-solid fa-cart-flatbed`}
            title={`Secure Shopping`}
            detail={`You're in safe hands`}
          />
          <ProvideService
            icon={`fa-solid fa-recycle`}
            title={`Made In India`}
            detail={`You're in safe hands`}
          />
        </div>

        {/* Decription */}
        <div className="allProfileDetails m40-top">
          <ul className="mainProfileDatails p0">
            <li
              onClick={() => setDetails(0)}
              className={details == 0 && "detailActive"}
            >
              Product Details
            </li>
            <li
              onClick={() => setDetails(1)}
              className={details == 1 && "detailActive"}
            >
              Product Care
            </li>
            <li
              onClick={() => setDetails(2)}
              className={details == 2 && "detailActive"}
            >
              Manufacture
            </li>
          </ul>
          <div className="displayProfileDetail m10-top">
            <div
              className={`${
                details == 0 ? "d-block" : "d-none"
              } productAllDetails d-flex`}
            >
              <ul className="me-4">
                <li>
                  Craft :{" "}
                  <span className="text-dark">{productShow?.craft}</span>
                </li>
                <li>
                  Length :{" "}
                  <span className="text-dark">{productShow?.size}</span>
                </li>
                <li>
                  Pattern :{" "}
                  <span className="text-dark">{productShow?.patten}</span>
                </li>
                <li>
                  Work : <span className="text-dark">{productShow?.work}</span>
                </li>
                <li>
                  Purity :{" "}
                  <span className="text-dark">{productShow?.purity}</span>
                </li>
              </ul>
              <ul>
                <li>
                  Blouse Fabric :{" "}
                  <span className="text-dark">{productShow?.febric}</span>
                </li>
                <li>
                  Blouse Length :{" "}
                  <span className="text-dark">
                    {productShow?.allProduct?.[countArray]?.sizeB}
                  </span>{" "}
                </li>
                <li>
                  Blouse Pattern :{" "}
                  <span className="text-dark">{productShow?.patten}</span>
                </li>
                <li>
                  Blouse work :{" "}
                  <span className="text-dark">{productShow?.work}</span>
                </li>
                {/* <li>
                  Blouse Color : {productShow?.allProduct?.[countArray]?.colorB}
                </li> */}
              </ul>
            </div>
            <ol className={details == 1 ? "d-block" : "d-none"}>
              {productCare?.map((res) => {
                return <li>{res.title}</li>;
              })}
            </ol>
          </div>
          <ul className={details == 2 ? "d-block" : "d-none"}>
            <b>{setting?.menufacture}</b>
          </ul>
        </div>
      </div>
    </section>
  );
};

export const ProvideService = (props) => {
  const { icon, title, detail } = props;
  return (
    <div className="col-xl-3 col-sm-4 col-xsm-6 col-12 m10-bottom">
      <div className="provideService d-flex align-items-center ">
        <div className="provideServiceIcon">
          <i class={icon}></i>
        </div>
        <div className="provideServiceDetails">
          <div className="fw-500 detailTitle">{title}</div>
          <div className="text-gray details">{detail}</div>
        </div>
      </div>
    </div>
  );
};

export default SareeDetails;
