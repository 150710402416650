import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../redux/slice/dialogSlice";
import Input, { Select, Textarea } from "./extra/Input";
import Button from "./extra/Button";
import { editData, objectToFormData, submitData } from "./utils/fuction";
import { addAddress, deleteAddress, updateAddress } from "../redux/slice/userAuthSlice";
import { ratingCreate } from "../redux/slice/reviewSlice";

const RatingDialogue = ({ data, setData }) => {
  const { dialogueData } = useSelector(
    (state) => state.dialogue
  );

  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const dispatch = useDispatch();

  // hanlde hover and rating
  const [rating, setRating] = useState(0);
  const [ratingShow, setRatingShow] = useState(0);

  const handleStarClick = (selectedRating) => {
    if (selectedRating === rating) {
      setRating(selectedRating - 0.5);
    } else {
      setRating(selectedRating);
    }
  };
  function handleHover(star) {
    setRatingShow(star);
  }

  const handleSubmit = (e) => {
    const addData = submitData(e);
    const payload = { ...addData, userId: parsedSessionUserId._id, ratingCount: rating }
    const formData = objectToFormData(payload)
    console.log("addData", addData);
    if (addData) {
      dispatch(ratingCreate(formData))
      dispatch(closeDialog())
    }
  };


  return (
    <div className="dialog">

      <div className="w-100">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-md-9 col-11">
            <div className="mainDiaogBox">
              <div className="row justify-content-between align-items-center formHead">
                <div className="col-8">
                  <h2 className="text-second m0">Add Review</h2>
                </div>
                <div className="col-4">
                  <div
                    className="closeButton"
                    onClick={() => {
                      dispatch(closeDialog());
                    }}
                  >
                    <i className="ri-close-line"></i>
                  </div>
                </div>
              </div>
              <form id='checkoutForm' onSubmit={handleSubmit}>
                <div className='m40-top border-bottom-gray2-1 p30-bottom'>
                  <div className="row">
                    <div className="col-6">
                      <Input
                        type={`text`}
                        id={`fullName`}
                        name={`fullName`}
                        label={`Full Name`}
                        placeholder={`Full Name`}
                        errorMessage={`Enter Full Name`}
                        value={parsedSessionUserId.name}
                        readOnly
                      />
                    </div>
                    <div className="col-6">
                      <Input
                        type={`text`}
                        id={`productCode`}
                        name={`productCode`}
                        label={`Product Code`}
                        placeholder={`Product Code`}
                        errorMessage={`Enter Product Code`}
                        value={dialogueData}
                        readOnly
                      />
                    </div>
                    <div className="col-12">
                      <div>Rating</div>
                      <div className="fs-26">
                        {[1, 2, 3, 4, 5].map((star) => (
                          <span
                            key={star}
                            onClick={() => handleStarClick(star)}
                            onMouseEnter={() => handleHover(star)}
                            onMouseLeave={() => handleHover(0)}
                            className={`star ${star <= ratingShow ? 'active' : ''}`}
                          >
                            {star <= Math.floor(rating) ? (
                              <i className="ri-star-fill" style={{ color: 'gold' }} ></i>
                            ) : star === Math.ceil(rating) && rating % 1 === 0.5 ? (
                              <i className="ri-star-half-line" style={{ color: 'gold' }} ></i>
                            ) : (
                              <i className="ri-star-line"></i>
                            )}
                          </span>
                        ))}
                      </div>
                    </div>
                    <div className="col-12">
                      <Textarea
                        id={`ratingText`}
                        name={`ratingText`}
                        label={`Review`}
                        row={3}
                        placeholder={`Review`}
                        errorMessage={`Enter Review`}

                      />
                    </div>
                    <div className="col-12">
                      <Input
                        type={`file`}
                        id={`image`}
                        name={`image`}
                        label={`Image`}
                        errorMessage={`Enter Image`}
                        ignore={true}
                      />
                    </div>

                  </div>
                </div>
                <div className="primeButton">
                  <button type='submit'>Add Review</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RatingDialogue;


// ======================== RatingForm =======================
export const RatingForm = () => {

  return (
    <div className='m40-top border-bottom-gray2-1 p30-bottom'>
      <div className="row">
        <div className="col-6">
          <Input
            type={`text`}
            id={`fullName`}
            name={`fullName`}
            label={`Full Name`}
            placeholder={`Full Name`}
            errorMessage={`Enter Full Name`}
          />
        </div>
        <div className="col-6">
          <Input
            type={`text`}
            id={`email`}
            name={`email`}
            label={`Full Email`}
            placeholder={`Full Email`}
            errorMessage={`Enter Full Email`}
            readOnly
          />
        </div>
        <div className="col-12">
          <Input
            type={`text`}
            id={`title`}
            name={`title`}
            label={`Full Title`}
            placeholder={`Full Title`}
            errorMessage={`Enter Full Title`}
            readOnly
          />
        </div>
        <div className="col-12">
          <Textarea
            id={`ratingText`}
            name={`ratingText`}
            label={`Enter Reviwe`}
            row={3}
            placeholder={`Enter Reviwe`}
            errorMessage={`Enter Reviwe`}
          />
        </div>
        <div className="col-12">
          <Input
            type={`file`}
            id={`Image`}
            name={`Image`}
            label={`Image`}
            errorMessage={`Enter Image`}
          />
        </div>

      </div>
    </div>
  );
}
