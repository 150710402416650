import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  productCare: [],
  isLoading: false,
  isSkeleton: false,
};
export const productCareGet = createAsyncThunk("productCare/show", async () => {
  return apiInstance.get("productCare/show");
});

const productCareSlice = createSlice({
  name: "productCareSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // productCareGet
    builder.addCase(productCareGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(productCareGet.fulfilled, (state, action) => {
      state.productCare = action.payload.productCare;
      state.isSkeleton = false;
    });
    builder.addCase(productCareGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });
  },
});
export default productCareSlice.reducer;
