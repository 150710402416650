import React, { useState } from "react";
import Header from "../globle/Header";
import Footer from "../globle/Footer";
import Poster2 from "../extra/Poster2";
import Saree1 from "../../assets/images/saree1.png";
import UserInfo from "./UserInfo";
import UserProfileAddress from "./UserProfileAddress";
import { warning } from "../utils/Alert";
import { logout } from "../../redux/slice/userAuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../utils/config";

const UserProfile = () => {
  const [path, setPath] = useState(1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loginUser, isAuth } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);
  const realUser = loginUser || parsedSessionUserId;

  const handleLogout = () => {
    const data = warning(
      "",
      "Logout To this Website?",
      "Logout",
      "ri-logout-circle-r-line"
    );
    data
      .then((logouts) => {
        const yes = logouts.isConfirmed;
        console.log("yes", yes);
        if (yes) {
          dispatch(logout());
          navigate("/");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <Header />

      <div className="mainUserProfile m40-top">
        <div className="container">
          <Poster2 title={`Profile`} />
          <div className="myProfile m40-top">
            <div className="row">
              <div className="col-lg-3 col-md-4 col-12">
                <div className="profileNav fs-lg-20 fs-md-18 fs-16">
                  <div className="profileInfo d-flex align-items-center">
                    <div className="profileImage rounded-5 overflow-hidden hw-lg-50 hw-40 m20-md-right m10-right border-second-2">
                      <img
                        src={baseURL + realUser.profileImage}
                        alt=""
                        className="w-100"
                      />
                    </div>
                    <div className="profileName">
                      <div className="fw-700 text-capitalize">
                        {realUser.name}
                      </div>
                      <div className="text-success fs-md-18 fs-16">
                        #{realUser.customerId}
                      </div>
                    </div>
                  </div>
                  <ul className="mainProfileMenu m40-top">
                    <li className="d-md-block d-none">
                      <a href="#">
                        <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                          <span>
                            <i class="ri-settings-3-line fs-lg-24 fs-md-22 fs-20 m10-right"></i>
                          </span>
                          <span>Account Setting</span>
                        </div>
                        <ul className="subProfileMenu d-md-block d-flex">
                          <li>
                            <a href="#" onClick={() => setPath(1)}>
                              <div
                                className={`navIcons fs-lg-16 fs-14 ${
                                  path == 1 && "text-second"
                                }`}
                              >
                                <span>
                                  <i class="ri-subtract-line m10-right"></i>
                                </span>
                                <span>Personal Info</span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="#" onClick={() => setPath(2)}>
                              <div
                                className={`navIcons fs-lg-16 fs-14 ${
                                  path == 2 && "text-second"
                                }`}
                              >
                                <span>
                                  <i class="ri-subtract-line m10-right"></i>
                                </span>
                                <span>Manage Address</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                        {/* <div className="showInfo text-dark d-md-none">
                          {path == 1 && <UserInfo />}
                          {path == 2 && <UserProfileAddress />}
                        </div> */}
                        {/* <div className="showInfo text-dark d-none">
                          {path == 1 && <UserProfileAddress />}
                          {path == 2 && <UserInfo />}
                        </div> */}
                      </a>
                    </li>
                    <li>
                      <Link to="/wishlist">
                        <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                          <span>
                            <i class="ri-heart-3-line fs-lg-24 fs-md-22 fs-20 m10-right"></i>
                          </span>
                          <span>My Wishlist</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cart">
                        <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                          <span>
                            <i class="ri-shopping-cart-2-line fs-lg-24 fs-md-22 fs-20 m10-right"></i>
                          </span>
                          <span>My Cart</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <Link to="/order">
                        <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                          <span>
                            <i class="ri-box-1-line fs-lg-24 fs-md-22 fs-20 m10-right"></i>
                          </span>
                          <span>My Order</span>
                        </div>
                      </Link>
                    </li>
                    <li>
                      <a href="#" onClick={() => handleLogout()}>
                        <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                          <span>
                            <i class="ri-logout-circle-line fs-lg-24 fs-md-22 fs-20 m10-right"></i>
                          </span>
                          <span>Logout</span>
                        </div>
                      </a>
                    </li>
                    <li className="d-md-none">
                      <a href="#">
                        <div className="navIcons fs-lg-20 fs-md-18 fs-16">
                          <span>
                            <i class="ri-settings-3-line fs-lg-24 fs-md-22 fs-20 m10-right"></i>
                          </span>
                          <span>Account Setting</span>
                        </div>
                        <ul className="subProfileMenu d-md-block d-flex">
                          <li>
                            <a href="#" onClick={() => setPath(1)}>
                              <div
                                className={`navIcons fs-lg-16 fs-14 ${
                                  path == 1 && "text-second"
                                }`}
                              >
                                <span>
                                  <i class="ri-subtract-line m10-right"></i>
                                </span>
                                <span>Personal Info</span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="#" onClick={() => setPath(2)}>
                              <div
                                className={`navIcons fs-lg-16 fs-14 ${
                                  path == 2 && "text-second"
                                }`}
                              >
                                <span>
                                  <i class="ri-subtract-line m10-right"></i>
                                </span>
                                <span>Manage Address</span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-9 col-md-8 col-12">
                <div className="showInfo">
                  {path == 1 && <UserInfo />}
                  {path == 2 && <UserProfileAddress />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default UserProfile;
