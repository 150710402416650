import React from 'react';
import Header from './globle/Header';
import Footer from './globle/Footer';
import Banner from './extra/Banner';
import Title from './extra/Title';
import Poster from './components/Poster';
import Poster2 from './extra/Poster2';
import Service from './components/Service';
const About = () => {


  return (

    <>
      <Header />

      <div className="mainAbout m40-top">
        <div className="container">
          <Poster2 title={`About Us`} />
          <Banner />
          <div className="myAbout m20-top fs-md-24 fs-sm-20 fs-16 text-gray">
            <div className="container">
              <p>
                We are on mission to empower Women's . Thevini believes in self reliant women.We gives hug chance in our
                organisation for working with us apply their ideas & grow together and became Self-esteem. Our 75% Employees
                are Women. Kanjivaram, Patola, Paithani & Wedding Sarees at Great Prices. Bahuji Provide high
                Valued Goods at Affordable Price. Having 7
              </p>
              <p>We are on mission to empower Women's . Thevini believes in self reliant women.We gives hug chance in our
                organisation for working with us apply their ideas & grow together and became Self-esteem. Our 75% Employees
                are Women. Kanjivaram, Patola, Paithani & Wedding Sarees at Great Prices. Bahuji Provide high
                Valued Goods at Affordable Price. Having 7
              </p>
              <h4 className='text-second'>" Empowering a woman empowers next generations "</h4>
              <p>We are Provide Banarasi, Silk, Kanjivaram, Patola, Paithani & Wedding Sarees at Great Prices. Bahuji Provide high
                Valued Goods at Affordable Price. Having 7 Years of Great Textile Manufacturing experience Serving Stylish &
                Premium Sarees at Best Prices.</p>
              <p>We are Provide Banarasi, Silk, Kanjivaram, Patola, Paithani & Wedding Sarees at Great Prices. Bahuji Provide high
                Valued Goods at Affordable Price. Having 7 Years of Great Textile Manufacturing experience Serving Stylish &
                Premium Sarees at Best Prices.</p>
            </div>
          </div>
        </div>
      </div>

      <Service />
      <Footer />
    </>
  );
}

export default About;
