import React from 'react'
import BannerImg from '../../assets/images/banner1.png'

const Banner = ({ img }) => {
  return (
    <section id='Banner'>
      <div className='container'>
        <div className="mainBanne">
          <img src={img} alt="" />
        </div>
      </div>
    </section>
  )
}

export default Banner;

export const Notice = ({ text }) => {
  return (
    <section id='Notice'>
      <div className='container'>
        <div className="mainNotice bg-second text-center text-light midBox">
          <p>{text}</p>
        </div>
      </div>
    </section>
  )
}
