import React, { useEffect, useState } from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { useDispatch, useSelector } from 'react-redux';
import { bannerGet } from '../../redux/slice/bannerSlice';
import { baseURL } from '../utils/config';
const Slider = () => {
  const dispatch = useDispatch()
  const { banner, isSkeleton } = useSelector((state) => state.banner);

  useEffect(() => {
    dispatch(bannerGet())
  }, []);

  console.log("banner", banner);


  return (
    <>
      <section id='Slider' className='p0 animate__animated animate__fadeInRight'>
        <div className="mainSlider bg-light">
          <div className="container-fluid p0">
            <OwlCarousel className='owl-theme' items={1} loop nav navClass={['fa-solid fa-angles-left prev', 'fa-solid fa-angles-right next']}
              autoplay={true} autoplayHoverPause={true} animateIn={`animate__fadeIn`}
            >
              {
                !isSkeleton ? (
                  banner?.map((res) => {
                    return (
                      <div class='item'>
                        <div className="bannerImage">
                          <img src={baseURL + res.image} alt="Banner" />
                        </div>
                      </div>
                    )
                  })
                ) : ([...Array(3)]?.map((res) => {
                  return (
                    <div class='item'>
                      <div className="bannerImage skeleton"></div>
                    </div>
                  )
                }))
              }
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default Slider
