import React, { useEffect, useRef, useState } from "react";
import Title from "../extra/Title";
import OwlCarousel from "react-owl-carousel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addProductToWishlist,
  productGet,
} from "../../redux/slice/productSlice";
import { baseURL } from "../utils/config";
import { addCart } from "../../redux/slice/cartSlice";
import "aos/dist/aos.css";
import Aos from "aos";

const BestSeller = ({ effect }) => {
  const dispatch = useDispatch();

  const { loginUser } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const { product, isSkeleton } = useSelector((state) => state.product);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(productGet(loginUser._id || parsedSessionUserId?._id));
  }, []);
  useEffect(() => {
    dispatch(productGet(loginUser._id || parsedSessionUserId?._id));
  }, [loginUser]);

  useEffect(() => {
    setData(product);
  }, [product]);

  return (
    <section id="BestSeller" className="carousel-mini">
      <div className="container">
        <Title name={`Our Best Seller`} />

        <div className="row">
          {!isSkeleton ? (
            data?.length > 0 ? (
              <>
                {data.map((pair, index) => (
                  <div
                    className="col-xxl-2 col-md-3 col-sm-4 col-6"
                    key={index}
                  >
                    <BestCollection
                      className={`m15-bottom`}
                      data={pair}
                      effect={effect}
                    />
                  </div>
                ))}
              </>
            ) : (
              [...Array(5)].map((_, index) => (
                <ProductSkeleton
                  key={index}
                  className={`col-xxl-2 col-md-3 col-sm-4 col-6`}
                />
              ))
            )
          ) : (
            [...Array(6)].map((_, index) => (
              <ProductSkeleton
                key={index}
                className={`col-xxl-2 col-md-3 col-sm-4 col-6`}
              />
            ))
          )}
        </div>
      </div>
    </section>
  );
};

export const ProductSkeleton = (props) => {
  return (
    <div className={props.className}>
      <div className="bestSeller">
        <div className="bestSellerImage skeleton height-xxl-350 height-xl-400 height-lg-350 height-sm-300 height-smm-300 height-xsm-240 height-210 bg-gray"></div>
        <div
          className="bestSellerTitle skeleton m10-top"
          style={{ height: "20px" }}
        ></div>
        <div
          className="bestSellerColor skeleton m2-top"
          style={{ height: "20px", width: "60%" }}
        ></div>
        <div
          className="bestSellerPrice skeleton m2-top"
          style={{ height: "20px", width: "30%" }}
        ></div>
      </div>
    </div>
  );
};
export const BestCollection = (props) => {
  console.log("props.data", props.data);
  // props.data &&
  const data = props.data;

  const { className, onClick, statusType } = props;
  const { loginUser, isAuth } = useSelector((state) => state.userAuth);

  const navigate = useNavigate();
  const [count, setCount] = useState(0);

  const handleShowImage = (id, productId) => {
    localStorage.setItem("productCode", id);
    localStorage.setItem("productId", productId);
    navigate(`/profile/${id}`, { state: { productId } });
    const myDiv = document.getElementById("SareeProfileMain");
    if (myDiv) {
      myDiv.scrollTo(0, 0);
    }
  };

  const dispatch = useDispatch();

  const handleWishlist = (productId) => {
    if (isAuth) {
      dispatch(
        addProductToWishlist({ userId: loginUser?._id, productId, statusType })
      );
    } else {
      navigate("/login");
    }
  };

  const handleAddToCart = () => {
    if (isAuth) {
      dispatch(
        addCart({
          productId: data?.productId[count],
          userId: loginUser?._id,
          productCount: 1,
        })
      );
    } else {
      navigate("/login");
    }
  };

  const rating = data?.rating?.totalRating;

  const fullStars = Math.floor(rating);
  const hasHalfStar = rating - fullStars >= 0.5;

  const stars = [];

  for (let i = 1; i <= 5; i++) {
    if (i <= fullStars) {
      stars.push(<i key={i} className="ri-star-fill"></i>);
    } else if (i === fullStars + 1 && hasHalfStar) {
      stars.push(<i key={i} className="ri-star-half-line"></i>);
    } else {
      stars.push(<i key={i} className="ri-star-line"></i>);
    }
  }
  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div className={className}>
      <div className="bestSeller" data-aos={props.effect} data-aos-delay="300">
        <div className="bestSellerImage height-xxl-350 height-xl-400 height-lg-350 height-sm-300 height-smm-300 height-xsm-240 height-210 ">
          <img src={baseURL + data?.productImage[count]} alt="saree" />
          <div className="productImageButton fs-20">
            <button
              className={`${data?.wishlist[count] && "bg-light"}`}
              onClick={() => handleWishlist(data?.productId[count])}
            >
              {data?.wishlist[count] ? (
                <i class="ri-heart-3-fill text-danger"></i>
              ) : (
                <i class="ri-heart-3-line "></i>
              )}
            </button>
            {!data?.outOfStock && (
              <button onClick={() => handleAddToCart()}>
                <i class="ri-shopping-cart-fill"></i>
              </button>
            )}
          </div>
          <div
            className="productImageQuike fs-16"
            onClick={() =>
              handleShowImage(data?.productCode, data?.productId[count])
            }
          >
            <button>
              Show <i class="ri-eye-line"></i>
            </button>
          </div>

          {data.outOfStock && (
            <div className="productOutOfStock">Out of stock</div>
          )}
        </div>

        <div
          className="bestSellerTitle cursor-pointer"
          onClick={() =>
            handleShowImage(data?.productCode, data?.productId[count])
          }
        >
          <h4 className="fw-600  fs-lg-18 fs-16" title={data?.title}>
            {data?.title}
          </h4>
        </div>

        <div className="bestSellerColor">
          <div className="productRating">
            {data?.rating?.total > 0 && (
              <span className="rateCount">{`(${data?.rating?.total} Review)`}</span>
            )}
            <span className="ratingStart text-warning">{stars}</span>
          </div>
          {data?.price && (
            <div className="bestSellerPrice mt-1 fs-lg-18 fs-16">
              <div>₹{data?.price}</div>
              <div className="fs-lg-16 fs-14">
                <del>₹{data?.oldPrice}</del>
              </div>
              <div className="text-success m10-left fs-lg-16 fs-14 text-overflow-1">
                {data?.discount}% off
              </div>
            </div>
          )}

          {data?.color && (
            <div className="productColor ">
              {data?.color?.map((res, i) => (
                <span
                  style={{ backgroundColor: res }}
                  onClick={() => setCount(i)}
                  className={count == i && "round"}
                ></span>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BestSeller;
