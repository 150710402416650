import React, { useEffect } from 'react';
import Title from '../extra/Title';
import $ from "jquery"
import { useDispatch, useSelector } from 'react-redux';
import { policyGet } from '../../redux/slice/policySlice';


const Policy = () => {

  const dispatch = useDispatch()
  const { policy, isSkeleton } = useSelector((state) => state.policy);

  useEffect(() => {
    dispatch(policyGet())
  }, []);

  return (
    <section id='Policy'>
      <PolicyScript data={policy} />
      <div className="container">
        <Title name={`Our Policy`} />

        <ul className="mainPolicy m35-top m0 p0 fs-24 fw-500 font-second">
          {
            !isSkeleton ? (
              policy?.map((res, i) => {
                return (
                  <SinglePolicy key={i} title={res.title} dec={res.dec} />
                )
              })
            ) : ([...Array(3)]?.map((res) => {
              return (
                <div class='item'>
                  <div className="policyImage skeleton"></div>
                </div>
              )
            }))
          }

        </ul>
      </div>

    </section>
  );
};

export const SinglePolicy = (props) => {
  const { title, dec } = props;
  return (
    <li className=''>
      <div className="mainPolicytitle betBox border-bottom cursor-pointer  fs-sm-18 fs-16">
        <p className='m20-y text-capitalize'>{title}</p>
        <i class="fa-solid fa-angle-right"></i>
      </div>
      <ul className="subPolicy p0 m0-y">
        <li className='m10-y'>
          <p className='p20 fs-sm-16 fs-14'>{dec} </p>
        </li>
      </ul>
    </li>
  )
}

export default Policy;


export const PolicyScript = ({ data }) => {
  useEffect(() => {

    // ================ Policy Toggle ==================

    const handlePolicyToggle = (event) => {
      const target = event.currentTarget;
      $(".subPolicy").not($(target).next(".subPolicy")).slideUp();
      $(".mainPolicytitle i").not($(target).children("i")).removeClass("rotate90");
      $(target).next(".subPolicy").slideToggle();
      $(target).children("i").toggleClass("rotate90");
    }
    $(".subPolicy").hide();
    $(".mainPolicy > li > .mainPolicytitle").on("click", handlePolicyToggle);

    return () => {
      $(".mainPolicy > li > .mainPolicytitle").off("click", handlePolicyToggle);
    }

  }, [data]);

  return null;
};

