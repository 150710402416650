import React, { useEffect, useState } from "react";
import Title from "../extra/Title";
import { BestCollection, ProductSkeleton } from "../components/BestSeller";
import { useDispatch, useSelector } from "react-redux";
import { colletionProduct } from "../../redux/slice/productSlice";
import Poster from "../components/Poster";
import Pagination from "../extra/Pagination";
import Cart from "../../assets/images/cart.png";
import { ProductFilter } from "./Collection";

const WeddingCollection = () => {
  const dispatch = useDispatch();

  // Pagination Both
  const [rowsPerPage, setRowsPerPage] = useState(40);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { loginUser } = useSelector((state) => state.userAuth);
  const sessionUserId = sessionStorage.getItem("token");
  const parsedSessionUserId = JSON.parse(sessionUserId);

  const { weddingColletion, totalCount, isSkeleton } = useSelector(
    (state) => state.product
  );
  const [filter, setFilter] = useState({});
  const payload = {
    page,
    type: "wedding",
    category: filter.categoryFilter,
    price: filter.priceFilter,
    color: filter.colorFilter,
    febric: filter.febricFilter,
    userId: loginUser._id || parsedSessionUserId?._id,
  };
  useEffect(() => {
    dispatch(colletionProduct(payload));
    window.scrollTo(0, 0);
  }, [page, filter, loginUser]);

  return (
    <section id="WeddingCollection">
      <Poster title={`Wedding Colletion`} />
      <div className="container">
        <div className="collectionHead">
          <div className="row justify-content-between align-items-center">
            <div className="col-7">
              <Title name={`Wedding Colletion`} />
            </div>
            <div className="col-5 text-end">
              <span className="text-gray ">{totalCount} Result</span>
            </div>
          </div>
        </div>
        <div className="mainCollection">
          <div className="row">
            <div className="col-xxl-2 col-lg-3 col-12">
              {/* {weddingColletion?.length > 0 && <ProductFilter setFilter={setFilter} />} */}
              <ProductFilter setFilter={setFilter} />
            </div>
            <div className="col-xxl-10 col-lg-9 col-12">
              <div className="row row-cols-4 m20-top">
                {!isSkeleton ? (
                  weddingColletion?.length > 0 ? (
                    <>
                      {weddingColletion?.map((res) => {
                        return (
                          <>
                            <div className="col w-xxl-20 w-xl-25 w-lg-33 w-md-33 w-50 m20-bottom">
                              <BestCollection data={res} statusType="wedding"  effect={`zoom-in`} />
                            </div>
                          </>
                        );
                      })}
                      <Pagination
                        type={"server"}
                        serverPage={page}
                        setServerPage={setPage}
                        serverPerPage={rowsPerPage}
                        onPageChange={handleChangePage}
                        totalData={totalCount}
                      />
                    </>
                  ) : (
                    <img src={Cart} alt="" className="noData" />
                  )
                ) : (
                  [...Array(10)]?.map(() => {
                    return (
                      <div className="col w-xxl-20 w-xl-25 w-lg-33 w-md-33 w-50 m20-bottom">
                        <ProductSkeleton />
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const SelectBox = () => {
  return (
    <div>
      <div className="mainSelectBox">
        <div className="selectItem">
          <div class="dropdown">
            <button
              class="dropdown-toggle selectBox betBox m-auto-left"
              type="button"
              data-bs-toggle="dropdown"
            >
              <span className="d-flex">
                <p className="m0 m10-right text-gray">Short by : </p>
                <p className="m0">Dropdown button</p>
              </span>
              <i class="fa-solid fa-chevron-down"></i>
            </button>
            <ul class="dropdown-menu">
              <li>Action</li>
              <li>Another action</li>
              <li>Something else here</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeddingCollection;
