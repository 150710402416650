import React, { useEffect, useState } from 'react'
import Slider from './components/Slider'
import Category from './components/Category'
import BestSeller from './components/BestSeller'
import Banner, { Notice } from './extra/Banner'
import Budget from './components/Budget'
import Policy from './components/Policy'
import News from './components/News'
import Service from './components/Service'
import Footer from './globle/Footer'
import Header from './globle/Header'
import { useLocation } from 'react-router-dom'
import { settingGet } from '../redux/slice/settingSlice'
import { useDispatch, useSelector } from 'react-redux'
import { baseURL } from './utils/config'


const Home = () => {
  const dispatch = useDispatch()
  const { setting } = useSelector((state) => state.setting);

  useEffect(() => {
    dispatch(settingGet())
  }, []);

  return (
    <div>
      <Header />
      <Slider />
      <Category />
      <BestSeller  effect={`zoom-in`} />
      <Banner img={baseURL + setting?.homeBanner} />
      <Budget />
      <Notice text={setting?.webText} />
      <Policy />
      {/* <News /> */}
      <Service />
      <Footer />
    </div>
  )
}

export default Home
