import React from 'react';
import Service1 from "../../assets/icons/shippng.png"
import Service2 from "../../assets/icons/returns.png"
import Service3 from "../../assets/icons/checkout.png"
import Service4 from "../../assets/icons/support.png"
import Title from '../extra/Title';


const Service = () => {
  return (
    <section id='Service'>
      <div className="container">
        <div className="mainService">
          <div className="row">
            <ServiceIcon image={Service1} name={`Global Shipping`} />
            <ServiceIcon image={Service2} name={`Flexible Returns`} />
            <ServiceIcon image={Service3} name={`Secure Checkout`} />
            <ServiceIcon image={Service4} name={`Dedicated support`} />
          </div>
        </div>
        <div className="mainNewLetter bg-fourth p30-y">
          <div className="row">
            <div className="col-xl-6 col-lg-8 col-11 m-auto font-second text-center">
              <Title name={`Newsletter`} className={`text-center`} />
              <p className='p15-top p10-x'>Subscribe to get special offers, free giveaways, and once-in-a-lifetime deals.
                We promise to send emails you will love.</p>
              <div className="emailSubmit">
                <input className='webEmail' type="email" placeholder="Example@gmail.com" />
                <button className="mk-btn">Join</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export const ServiceIcon = (props) => {
  const { image, name } = props
  return (
    <div className="col-lg-3 col-xsm-6 col-12">
      <div className="mainServiceBox">
        <div className="serviceIcon m30-bottom m-auto">
          <img src={image} alt="service" />
        </div>
        <div className="serviceName">
          <h5>{name}</h5>
        </div>
      </div>
    </div>
  )
}

export default Service;
