import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";
import { setToast } from "../../pages/extra/toast";

const initialState = {
  product: [],
  weddingColletion: [],
  newColletion: [],
  budgetColletion: [],
  colorCollection: [],
  febricCollection: [],
  productShow: {},
  category: {},
  totalCount: 0,
  isSkeleton: false,
  simpaleLoading: false,
};
export const productGet = createAsyncThunk("product/bestSeller", async (payload) => {
  return apiInstance.get(`product/bestSeller?userId=${payload}`);
});
export const productAll = createAsyncThunk("product/allProduct", async (payload) => {
  return apiInstance.get(`product/allProduct?userId=${payload.userId}&page=${payload.page}&category=${payload.category}&color=${payload.color}&febric=${payload.febric}&price=${payload.price}`);
});

export const productDetails = createAsyncThunk("product/showSingleProduct", async (payload) => {
  return apiInstance.get(`product/showSingleProduct?productCode=${payload.productCode}&userId=${payload.userId}`);
});

export const productCategory = createAsyncThunk("product/categoryWiseProduct", async (payload) => {
  return apiInstance.get(`product/categoryWiseProduct?userId=${payload.userId}&categoryId=${payload.categoryId}&page=${payload.page}&color=${payload.color}&febric=${payload.febric}&price=${payload.price}`);
});

export const colletionProduct = createAsyncThunk("product/colletionProduct", async (payload) => {
  return apiInstance.get(`product/colletionProduct?userId=${payload.userId}&page=${payload.page}&type=${payload.type}&category=${payload.category}&color=${payload.color}&febric=${payload.febric}&price=${payload.price}`);
});

export const productBudget = createAsyncThunk("product/budgetPoduct", async (payload) => {
  return apiInstance.get(`product/budgetPoduct?userId=${payload.userId}&page=${payload.page}&price=${payload.price}&category=${payload.category}&color=${payload.color}&febric=${payload.febric}`);
});

export const attributeWiseProduct = createAsyncThunk("product/getAttibuteWiseproduct", async (payload) => {
  return apiInstance.get(`product/getAttibuteWiseproduct?userId=${payload.userId}&type=${payload.type}&value=${payload.value}&category=${payload.category}&color=${payload.color}&febric=${payload.febric}&price=${payload.price}`);
});

export const addToWishlist = createAsyncThunk("wishlist/create", async (payload) => {
  return apiInstance.post(`wishlist/create?userId=${payload.userId}&productId=${payload.productId}`);
});

export const addProductToWishlist = createAsyncThunk("wishlist/createProduct", async (payload) => {
  return apiInstance.post(`wishlist/create?userId=${payload.userId}&productId=${payload.productId}`);
});

const productSlice = createSlice({
  name: "productSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // productGet
    builder.addCase(productGet.pending, (state, action) => {
      state.simpaleLoading = true;
    });
    builder.addCase(productGet.fulfilled, (state, action) => {
      state.product = action.payload.product;
      state.simpaleLoading = false;

    });
    builder.addCase(productGet.rejected, (state, action) => {
      state.simpaleLoading = false;
    });

    // productAll
    builder.addCase(productAll.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(productAll.fulfilled, (state, action) => {
      state.product = action.payload.product;
      state.totalCount = action.payload.totalCount;
      state.isSkeleton = false;

    });
    builder.addCase(productAll.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // productGet Details
    builder.addCase(productDetails.pending, (state, action) => {
      state.isSkeleton = true;
      state.productShow = {};
    });
    builder.addCase(productDetails.fulfilled, (state, action) => {
      state.productShow = action.payload.product;
      state.isSkeleton = false;

    });
    builder.addCase(productDetails.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // productGet Category Wise
    builder.addCase(productCategory.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(productCategory.fulfilled, (state, action) => {
      state.product = action.payload.product;
      state.category = action.payload.category;
      state.totalCount = action.payload.totalCount;
      state.isSkeleton = false;

    });
    builder.addCase(productCategory.rejected, (state, action) => {
      state.isSkeleton = false;
    });


    // productGet colletionProduct
    builder.addCase(colletionProduct.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(colletionProduct.fulfilled, (state, action) => {
      if (action.meta.arg.type == "wedding") {
        state.weddingColletion = action.payload.product;
      } else if (action.meta.arg.type == "new") {
        state.newColletion = action.payload.product;
      }
      state.totalCount = action.payload.totalCount;
      state.isSkeleton = false;

    });
    builder.addCase(colletionProduct.rejected, (state, action) => {
      state.isSkeleton = false;
    });

    // productGet productBudget
    builder.addCase(productBudget.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(productBudget.fulfilled, (state, action) => {
      state.budgetColletion = action.payload.product;
      state.totalCount = action.payload.totalCount;
      state.isSkeleton = false;

    });
    builder.addCase(productBudget.rejected, (state, action) => {
      state.isSkeleton = false;
    });


    // product Attribute
    builder.addCase(attributeWiseProduct.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(attributeWiseProduct.fulfilled, (state, action) => {
      if (action.meta.arg.type == "febric") {
        state.febricCollection = action.payload.product;
      } else if (action.meta.arg.type == "color") {
        state.colorCollection = action.payload.product;
      }
      state.totalCount = action.payload.totalCount;
      state.isSkeleton = false;

    });
    builder.addCase(attributeWiseProduct.rejected, (state, action) => {
      state.isSkeleton = false;
    });



    // Add to wishlist

    builder.addCase(addToWishlist.fulfilled, (state, action) => {
      if (action.payload.status) {
        const productShowIdx = state.productShow.allProduct.findIndex((product) => product._id === action.payload.data.productId);
        console.log("action.payload.data.productId", action.payload.data.productId);

        if (productShowIdx !== -1) {
          state.productShow.allProduct[productShowIdx] = { ...state.productShow.allProduct[productShowIdx], ...action.payload };
        }
        setToast("success", action.payload.message)
      }
    });

    builder.addCase(addProductToWishlist.fulfilled, (state, action) => {
      const { status, data, message, wishlist } = action.payload;
      const { statusType } = action.meta.arg
   
      var stateType;
      if (statusType == "wedding") {
        stateType = state.weddingColletion
      } else if (statusType == "new") {
        stateType = state.newColletion
      } else if (statusType == "budget") {
        stateType = state.budgetColletion
      } else if (statusType == "color") {
        stateType = state.colorCollection
      } else if (statusType == "febric") {
        stateType = state.febricCollection
      } else {
        stateType = state.product
      }
      if (status) {
        const productIdx = stateType.findIndex(productCode => productCode._id === data.productCode);
        if (productIdx !== -1) {
          const product = stateType[productIdx];
          const productShowIdx = product.productId.findIndex(id => id === data.productId);
          if (productShowIdx !== -1) {
            product.wishlist[productShowIdx] = wishlist;
          }
        }
        setToast("success", message);
      }
    });

  },
});
export default productSlice.reducer;

