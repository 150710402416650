import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiInstance } from "../../api/axiosApi";

const initialState = {
  policy: [],
  isSkeleton: false
};
export const policyGet = createAsyncThunk("policy/show", async () => {
  return apiInstance.get("policy/show");
});

const policySlice = createSlice({
  name: "policySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // policyGet
    builder.addCase(policyGet.pending, (state, action) => {
      state.isSkeleton = true;
    });
    builder.addCase(policyGet.fulfilled, (state, action) => {
      state.policy = action.payload.policy;
      state.isSkeleton = false;

    });
    builder.addCase(policyGet.rejected, (state, action) => {
      state.isSkeleton = false;
    });

  },
});
export default policySlice.reducer;

