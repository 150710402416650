import { configureStore } from "@reduxjs/toolkit";
import userAuthSlice from "./slice/userAuthSlice";
import bannerSlice from "./slice/bannerSlice";
import dialogSlice from "./slice/dialogSlice";
import categorySlice from "./slice/categorySlice";
import productSlice from "./slice/productSlice";
import budgetSlice from "./slice/budgetSlice";
import extraSlice from "./slice/extraSlice";
import wishlistSlice from "./slice/wishlistSlice";
import cartSlice from "./slice/cartSlice";
import paymentSlice from "./slice/paymentSlice";
import orderSlice from "./slice/orderSlice";
import policySlice from "./slice/policySlice";
import reviewSlice from "./slice/reviewSlice";
import settingSlice from "./slice/settingSlice";
import productCareSlice from "./slice/productCareSlice";

// Enable Redux DevTools Extension

const store = configureStore({
  reducer: {
    userAuth: userAuthSlice,
    dialogue: dialogSlice,
    extra: extraSlice,
    banner: bannerSlice,
    category:categorySlice,
    budget: budgetSlice,
    product: productSlice,
    wishlist: wishlistSlice,
    cart: cartSlice,
    paymeny: paymentSlice,
    order: orderSlice,
    policy: policySlice,
    productCare: productCareSlice,
    review: reviewSlice,
    setting: settingSlice,
  },
});

export default store;
